globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"lIJ2EI2BF48Bn9bpmKxal"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
/* eslint-disable import/namespace */
import baseConfig from './sentry.base.config'
import * as Sentry from '@sentry/nextjs'

const isNotProduction = !process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT?.includes('production')
Sentry.init({
  ...baseConfig,
  // Replay may only be enabled for the client-side
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    ...(isNotProduction ? [Sentry.replayIntegration()] : []),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
